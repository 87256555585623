import React, { useState } from 'react'
import styled, { keyframes } from 'styled-components'
import scrollTo from 'gatsby-plugin-smoothscroll'

import Button from '../../components/ui/buttons'
import ContainerLayout from '../../components/ui/containerLayout'

import { useI18n, useCustomI18n } from '../../../locale.js'

import gridBg from '../../assets/images/elements/gridBg.svg'
import gamerPlayBg from '../../assets/images/bg/gamerPlayBg.png'
import fallingStar from '../../assets/images/elements/fallingStar.svg'

const Description = ({ city }) => {
  const [showDescription, setShowDescription] = useState(false)
  return (
    <ContainerLayoutStyled id='description'>
      <GridBgStyled src={gridBg} alt='Grid pattern' />
      <BlockStyled>
        <TitleStyled>
          {useI18n('main.description.welcome')} <br />
          <b>{useI18n('main.description.future')}</b>
        </TitleStyled>

        <ImageStyled src={gamerPlayBg} alt='Person play vr' />

        <SubtitleStyled>
          {useI18n('main.description.vrIn')} {useCustomI18n(city.declension)}
        </SubtitleStyled>
        <DescriptionStyled>
          {useI18n('main.description.vrInDetails')}{' '}
          {useCustomI18n(city.declension)} {useI18n('main.description.details')}
          <br />
        </DescriptionStyled>

        <DescriptionExtraStyled visible={showDescription}>
          {useI18n('main.description.moreDetails')}
          <br />
        </DescriptionExtraStyled>

        <br />

        <ShowMoreButtonStyled
          isOpened={showDescription}
          onClick={() => setShowDescription(!showDescription)}
        >
          {useI18n('main.description.showAll')}
        </ShowMoreButtonStyled>
        <br />
        <br />

        <ButtonStyled
          title={useI18n('main.description.wantToPlay')}
          variant='solid'
          defaultSize
          onClick={() => scrollTo('#booking')}
        />
      </BlockStyled>

      <HintTextStyled>Start game</HintTextStyled>
    </ContainerLayoutStyled>
  )
}
export default Description

const ContainerLayoutStyled = styled(ContainerLayout)`
  align-items: flex-start;
`

const BlockStyled = styled.div`
  margin-top: 50px;
  max-width: 50%;
  margin-left: auto;

  position: relative;

  &:before {
    content: '';
    width: 110px;
    height: 70px;
    position: absolute;
    left: 55%;
    top: -100px;

    background-image: url(${fallingStar});
    background-size: 95%;
    background-repeat: no-repeat;
  }

  @media ${(props) => props.theme.breakpoints.small} {
    margin-top: 0;
    max-width: 100%;
    &:before {
      content: unset;
    }
  }
`
const TitleStyled = styled.p`
  margin-bottom: 2.2rem;

  font-size: 2.5rem;
  line-height: 1.14;
  text-align: left;

  @media ${(props) => props.theme.breakpoints.small} {
    text-align: center;
    font-size: 1.7rem;
  }
`
const SubtitleStyled = styled.p`
  margin-bottom: 1.5rem;

  font-size: 1.1rem;
  line-height: 1.4;
  font-family: ${(props) => props.theme.fonts.futuraDemi};
  color: ${(props) => props.theme.colors.green};

  @media ${(props) => props.theme.breakpoints.small} {
    font-size: 1rem;
  }
`
const DescriptionStyled = styled.p`
  font-size: 1.1rem;
  line-height: 1.4;

  @media ${(props) => props.theme.breakpoints.small} {
    font-size: 1rem;
  }
`
const DescriptionExtraStyled = styled(DescriptionStyled)`
  display: ${(props) => (props.visible ? null : 'none')};
  margin-top: -20px;

  position: relative;
  animation: ${(props) => (props.visible ? slide : slideOut)} 0.5s ease forwards;
  opacity: 0;

  @media ${(props) => props.theme.breakpoints.medium} {
    margin-top: -230px;
  }
  @media ${(props) => props.theme.breakpoints.tablet} {
    margin-top: -300px;
  }
  @media ${(props) => props.theme.breakpoints.small} {
    margin-top: -170px;
  }
  @media ${(props) => props.theme.breakpoints.mobile} {
    margin-top: -220px;
  }
  @media ${(props) => props.theme.breakpoints.xsmall} {
    margin-top: -260px;
  }
`
const slide = keyframes`
  100% {
    opacity: 1;
    margin-top: 10px;
  }
`
const slideOut = keyframes`
  0% {
    opacity: 1;
    margin-top: 10px;
  }
`

const ImageStyled = styled.img`
  width: 100%;

  position: absolute;
  left: -100%;
  top: 0;
  z-index: 1;

  @media ${(props) => props.theme.breakpoints.small} {
    margin-bottom: 1.5rem;

    position: initial;
  }
`

const GridBgStyled = styled.img`
  width: 430px;
  object-fit: contain;

  position: absolute;
  top: 55%;
  left: 24%;

  @media ${(props) => props.theme.breakpoints.small} {
    width: 250px;

    left: unset;
    right: -20%;
  }
`

const ShowMoreButtonStyled = styled.button`
  display: flex;
  align-items: center;

  padding: 0;

  background: none;
  border: none;

  color: ${(props) => props.theme.colors.white};

  font-size: 0.9rem;

  &:after {
    content: '';

    width: 0;
    height: 0;
    margin-left: 10px;
    border-top: 5px solid ${(props) => props.theme.colors.green};

    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: ${(props) =>
      props.isOpened ? 'none' : `5px solid ${props.theme.colors.green}`};
    border-bottom: ${(props) =>
      props.isOpened ? `5px solid ${props.theme.colors.green}` : 'none'};
    z-index: 1;
  }
`

const HintTextStyled = styled.span`
  position: absolute;
  right: 10%;
  bottom: 7.5rem;

  color: #151c1a;
  font-size: 1.3rem;
  font-family: ${(props) => props.theme.fonts.futuraDemi};

  @media ${(props) => props.theme.breakpoints.medium} {
    position: initial;
    margin: 15px auto 0;
  }
`

const ButtonStyled = styled(Button)`
  @media ${(props) => props.theme.breakpoints.small} {
    width: 100%;
    margin: auto;
  }
`
