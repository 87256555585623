import React from "react"
import styled from "styled-components"
import { useBreakpoint } from "gatsby-plugin-breakpoints"

import ContainerLayout from "../../components/ui/containerLayout"

import { useI18n } from "../../../locale.js"

import rightHand from "../../assets/images/bg/rightHand.png"
import leftHand from "../../assets/images/bg/leftHand.png"

import cubesCloud from "../../assets/images/elements/cubesCloud.png"
import aroundCircle from "../../assets/images/about/360Circle.svg"
import closedClub from "../../assets/images/about/lockCircle.svg"
import pillsCircle from "../../assets/images/about/pillsCircle.svg"
import teamCircle from "../../assets/images/about/teamCircle.svg"

const AboutUs = () => {
  const breakpoints = useBreakpoint()

  return (
    <WrapStyled>
      <ContainerLayoutStyled id="about">
        {breakpoints.mobile ? null : (
          <ImageStyled src={cubesCloud} alt="Cubes pattern" />
        )}

        <TitleStyled>
          {useI18n("main.aboutUs.whyYou")} <b>{useI18n("main.aboutUs.comeAgain")}</b>
        </TitleStyled>

        <WrapperStyled>
          <ItemStyled>
            <ItemImageStyled src={aroundCircle} alt="Full freedom" />
            <div>
              <ItemTitleStyled>
                {useI18n("main.aboutUs.fullFreedom")}
              </ItemTitleStyled>
              <ItemDescriptionStyled>
                {useI18n("main.aboutUs.freedomDetails")}
              </ItemDescriptionStyled>
            </div>
          </ItemStyled>
          {/* <ItemStyled>
            <ItemImageStyled src={closedClub} alt="Closed club" />
            <div>
              <ItemTitleStyled>{useI18n("main.aboutUs.closedClub")}</ItemTitleStyled>
              <ItemDescriptionStyled>
                {useI18n("main.aboutUs.closedDetails")}
              </ItemDescriptionStyled>
            </div>
          </ItemStyled> */}
          <ItemStyled>
            <ItemImageStyled src={pillsCircle} alt="Special atmosphere" />
            <div>
              <ItemTitleStyled>{useI18n("main.aboutUs.atmosphere")}</ItemTitleStyled>
              <ItemDescriptionStyled>
                {useI18n("main.aboutUs.atmosphereDetails")}
              </ItemDescriptionStyled>
            </div>
          </ItemStyled>
          <ItemStyled>
            <ItemImageStyled src={teamCircle} alt="Team play" />
            <div>
              <ItemTitleStyled>
                {useI18n("main.aboutUs.multiplayerGame")}
              </ItemTitleStyled>
              <ItemDescriptionStyled>
                {useI18n("main.aboutUs.multiplayerDetails")}
              </ItemDescriptionStyled>
            </div>
          </ItemStyled>
        </WrapperStyled>
      </ContainerLayoutStyled>
    </WrapStyled>
  )
}
export default AboutUs

const WrapStyled = styled.div`
  overflow: hidden;
`

const ContainerLayoutStyled = styled(ContainerLayout)`
  > * {
    z-index: 1;
  }

  &:before {
    content: "";
    width: 480px;
    height: 460px;

    position: absolute;
    left: -15%;
    bottom: -2rem;

    background-image: url(${leftHand});
    background-size: 80%;
    background-repeat: no-repeat;
    background-position: center center;
  }

  &:after {
    content: "";
    width: 475px;
    height: 550px;

    position: absolute;
    right: -15%;
    bottom: -6rem;

    background-image: url(${rightHand});
    background-size: 80%;
    background-repeat: no-repeat;
    background-position: center center;
  }
  @media ${props => props.theme.breakpoints.small} {
    &:before {
      left: -30%;
      top: 12%;
    }
    &:after {
      right: -30%;
      bottom: -8rem;
    }
  }

  @media ${props => props.theme.breakpoints.mobile} {
    &:before {
      left: -70%;
      top: 12%;
    }
    &:after {
      right: -60%;
      bottom: -8rem;
    }
  }
`

const TitleStyled = styled.p`
  margin-bottom: 2.2rem;

  font-size: 2.5rem;
  line-height: 1.14;
  text-align: center;

  @media ${props => props.theme.breakpoints.small} {
    font-size: 1.7rem;
  }
`
const WrapperStyled = styled.div`
  display: flex;
  flex-wrap: wrap;

  margin-top: 5rem;
  z-index: 2;

  @media ${props => props.theme.breakpoints.small} {
    margin-top: 2rem;
  }
`
const ItemStyled = styled.div`
  display: flex;
  align-items: flex-start;

  width: 45%;
  margin: 0 auto 3rem;

  @media ${props => props.theme.breakpoints.small} {
    width: 100%;
    margin: 0 0 3rem;

    &:last-child {
      margin-bottom: 0;
    }
  }
`
const ItemImageStyled = styled.img`
  margin-right: 10px;
  margin-top: -15px;
  width: 140px;
  height: 140px;

  @media ${props => props.theme.breakpoints.tablet} {
    width: 85px;
    height: 85px;
  }
`

const ItemTitleStyled = styled.p`
  margin-bottom: 1.5rem;

  font-size: 1.2rem;
  font-family: ${props => props.theme.fonts.futuraDemi};

  @media ${props => props.theme.breakpoints.small} {
    margin-bottom: 1rem;
  }
`

const ItemDescriptionStyled = styled.p`
  font-size: 1rem;
  line-height: 1.41;

  @media ${props => props.theme.breakpoints.small} {
    margin-bottom: 0.9rem;
  }
`

const ImageStyled = styled.img`
  width: 408px;
  height: 250px;
  position: absolute;

  right: -15%;
  top: -5.5rem;
`
