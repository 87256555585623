import React, { useEffect, useState } from "react";
import styled from 'styled-components'
import scrollTo from 'gatsby-plugin-smoothscroll'
import { useLocation } from '@reach/router'

import LandingScreen from './Landing'
import Description from './Description'
import Price from './Price'
import AboutUs from './AboutUs'
import Rules from './Rules'
import Booking from './Booking'

import NavBar from '../../components/navbar'
import Reviews from '../../components/reviews'
import Contacts from '../../components/contacts'
import Footer from '../../components/footer'
import Games from '../../components/games'
import Seo from '../../components/seo'
import { ServerDataContext } from '../../../serverData';
import { graphql } from "gatsby";

const ContainerStyled = styled.div`
  min-height: 100vh;
  background: #030303;
  overflow: hidden;
`

export default function Main({ pageContext }) {
  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      setTimeout(() => scrollTo(location.hash), 100);
    }
  }, []);

  return (
    <ServerDataContext.Consumer>
      {({ dataLoaded, getData }) => {
        if (!dataLoaded) getData(pageContext.companyId);
        return (
          <ContainerStyled>
            <NavBar city={pageContext} />
            <LandingScreen city={pageContext} />
            <Description city={pageContext} />
            <AboutUs />
            <Games city={pageContext} />
            <Rules />
            <Price city={pageContext} />
            <Reviews />
            <Booking city={pageContext} />
            <Contacts city={pageContext} />
            <Footer />
          </ContainerStyled>
        );
      }}
    </ServerDataContext.Consumer>
  )
}



export const Head = ({ pageContext }) => (
  <>
    <SeoData context={pageContext} />
    <script
      dangerouslySetInnerHTML={{
        __html: `
            !function(f,b,e,v,n,t,s)
{if(f.fbq)return;n=f.fbq=function(){n.callMethod?
n.callMethod.apply(n,arguments):n.queue.push(arguments)};
if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
n.queue=[];t=b.createElement(e);t.async=!0;
t.src=v;s=b.getElementsByTagName(e)[0];
s.parentNode.insertBefore(t,s)}(window,document,'script',
'https://connect.facebook.net/en_US/fbevents.js');
 fbq('init', '1258133411516313'); 
fbq('track', 'PageView');
            `
      }}
    />
    <noscript>
      <img height="1" width="1"
        src="https://www.facebook.com/tr?id=1258133411516313&ev=PageView
&noscript=1"/>
    </noscript>
    {!!pageContext.fbPersonalId &&
      <>
        <script
          dangerouslySetInnerHTML={{
            __html: `
            !function(f,b,e,v,n,t,s)
{if(f.fbq)return;n=f.fbq=function(){n.callMethod?
n.callMethod.apply(n,arguments):n.queue.push(arguments)};
if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
n.queue=[];t=b.createElement(e);t.async=!0;
t.src=v;s=b.getElementsByTagName(e)[0];
s.parentNode.insertBefore(t,s)}(window,document,'script',
'https://connect.facebook.net/en_US/fbevents.js');
 fbq('init', '${pageContext.fbPersonalId}'); 
fbq('track', 'PageView');
            `
          }}
        />
        <noscript>
          <img height="1" width="1"
            src={`https://www.facebook.com/tr?id=${pageContext.fbPersonalId}&ev=PageView
&noscript=1`} />
        </noscript>
      </>
    }
  </>
)
const SeoData = ({ context }, props) => {
  const [lang, setLang] = useState('ua')
  // console.log(props)
  // useEffect(()=>{
  //   const locale = localStorage.getItem('locale')
  //   if(locale){
  //     setLang(locale)
  //   }
  // })
  return (
    <Seo
      context={context}
      title={`Matrix VR - Клуб віртуальної реальності у ${context.declension[lang]}`}
      description={`Клуб MATRIX у ${context.declension[lang]} пропонує своїм клієнтам тільки ті ігри, що відрізняються чудовою графікою та чітким зображенням. Це є запорукою максимального занурення та ваших позитивних емоцій.`}
    />
  )
}

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;