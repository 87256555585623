import React from "react"
import styled from "styled-components"
import scrollTo from "gatsby-plugin-smoothscroll"
import { useBreakpoint } from "gatsby-plugin-breakpoints"

import Button from "../../components/ui/buttons"
import ContainerLayout from "../../components/ui/containerLayout"

import { useI18n, useCustomI18n } from "../../../locale.js"

import sphereImage from "../../assets/images/elements/sphereOrbitImage.png"
import fallingStar from "../../assets/images/elements/fallingStar.svg"

const Landing = ({ city }) => {
  const breakpoints = useBreakpoint()

  return (
    <WrapStyled>
      <ContainerLayoutStyled id="home">
        <BlockStyled>
          <TitleStyled>
            {useI18n("main.firstScreen.title")}{" "}
            <b>
              {useI18n("main.firstScreen.vnIn")} {useCustomI18n(city.declension)}
            </b>
          </TitleStyled>
          {/* <SubtitleStyled>
            {useI18n("main.firstScreen.offer1")}
            <br /> <b>{useI18n("main.firstScreen.offer2")}</b>
          </SubtitleStyled> */}

          <ButtonStyled
            title={useI18n("main.firstScreen.wantToPlay")}
            variant="solid"
            defaultSize
            onClick={() => scrollTo("#booking")}
          />
        </BlockStyled>

        <ImageStyled src={sphereImage} alt="Sphere" />
        {breakpoints.mobile ? null : (
          <HintTextStyled>
            Start game/ <br />
            see you
          </HintTextStyled>
        )}
      </ContainerLayoutStyled>
    </WrapStyled>
  )
}
export default Landing

const WrapStyled = styled.div`
  overflow: hidden;
`

const ContainerLayoutStyled = styled(ContainerLayout)`
  margin-top: 100px;
  @media ${props => props.theme.breakpoints.laptop} {
    margin-top: 80px;
  }
  @media ${props => props.theme.breakpoints.tablet} {
    margin-top: 60px;
  }
  @media ${props => props.theme.breakpoints.mobile} {
    flex-direction: column-reverse;
  }
`

const BlockStyled = styled.div`
  max-width: 55%;
  margin-right: auto;
  margin-top: 5rem;

  position: relative;

  &:before {
    content: "";
    width: 150px;
    height: 90px;

    position: absolute;
    left: 45%;
    top: -150px;

    background-image: url(${fallingStar});
    background-size: 95%;
    background-repeat: no-repeat;
  }
  @media ${props => props.theme.breakpoints.tablet} {
    max-width: 70%;
    z-index: 1;
  }
  @media ${props => props.theme.breakpoints.mobile} {
    max-width: 100%;
    margin-top: 0;
    &:before {
      content: unset;
    }
  }
`
const TitleStyled = styled.p`
  margin-bottom: 1rem;

  font-size: 2.8rem;
  line-height: 1.14;

  @media ${props => props.theme.breakpoints.laptop} {
    font-size: 2.4rem;
  }

  @media ${props => props.theme.breakpoints.small} {
    font-size: 1.7rem;
  }
`
const SubtitleStyled = styled.p`
  margin-bottom: 2rem;

  font-size: 1.2rem;

  br {
    display: none;
  }
  @media ${props => props.theme.breakpoints.small} {
    font-size: 1.1rem;

    br {
      display: block;
    }
  }
`
const ImageStyled = styled.img`
  width: 60%;

  position: absolute;
  right: -10%;

  @media ${props => props.theme.breakpoints.mobile} {
    width: calc(100% + 4rem);

    position: initial;
    right: unset;
  }
`
const HintTextStyled = styled.span`
  position: absolute;
  left: 50%;
  bottom: 6.5rem;

  color: #151c1a;
  font-size: 1.5rem;
`
const ButtonStyled = styled(Button)`
  @media ${props => props.theme.breakpoints.mobile} {
    width: 100%;
  }
`
