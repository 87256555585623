import React, {useContext} from "react";
import styled from "styled-components";
import scrollTo from "gatsby-plugin-smoothscroll";
import { navigate } from "gatsby";

import ContainerLayout from "../../components/ui/containerLayout";
import Button from "../../components/ui/buttons";

import { useI18n } from "../../../locale.js";
import { ServerDataContext } from '../../../serverData';

import greyBrackets from "../../assets/images/elements/greyBrackets.svg";
import greenBrackets from "../../assets/images/elements/greenBrackets.svg";

const Price = ({ city }) => {
  const { details } = useContext(ServerDataContext);
  const { prices } = city;
  const { schedule } = details;
  const timeUp = schedule?.monday[0].to;
  const weekdayTitle = useI18n("main.price.weekdays", { time: timeUp || '19' });
  const weekdayPrice = useI18n("main.price.weekdaysDetails");
  const weekendTitle = useI18n("main.price.weekends", { time: timeUp || '19' });
  const weekendPriceTitle = useI18n("main.price.weekendsDetails");
  const allWeek = useI18n("main.price.allWeek");
  const currencyText = useI18n("main.price.currency");
  const durationText = useI18n("main.price.duration");
  const bookText = useI18n("main.price.book");

  const isSamePrice = () => {
    if (!schedule) return prices.weekday === prices.weekend;

    if (schedule.monday.length > 1) {
      return schedule.monday[0].price === schedule.monday[1].price === schedule.sunday[0].price;
    } else {
      return schedule.monday[0].price === schedule.sunday[0].price
    }
  };

  return (
    <ContainerLayout id="prices">
      <TitleStyled>
        {useI18n("main.price.offer1")}
        <br />
        <b>{useI18n("main.price.offer2")}</b>
      </TitleStyled>

      <WrapperStyled isSamePrice={isSamePrice()}>
        {
          !isSamePrice() && (
            <ItemStyled>
              <ItemTitleStyled>{weekdayTitle}</ItemTitleStyled>
              <ItemDescriptionStyled>{weekdayPrice}</ItemDescriptionStyled>
              <ItemBlockStyled>
                <ItemPriceStyled>{schedule ? schedule.monday[0].price : prices.weekday}</ItemPriceStyled>
                <ItemPriceTextStyled>
                  <span>{currencyText}</span>
                  <p>{durationText}</p>
                </ItemPriceTextStyled>
              </ItemBlockStyled>
              <ButtonStyled
                  variant="outlined"
                  title={bookText}
                  onClick={() => scrollTo("#booking")}
              />
            </ItemStyled>
          )
        }

        <ItemStyled isHovered>
          <ItemTitleStyled>{isSamePrice() ? allWeek : weekendTitle}</ItemTitleStyled>
          <ItemDescriptionStyled>{weekendPriceTitle}</ItemDescriptionStyled>
          <ItemBlockStyled>
            <ItemPriceStyled>{schedule ? schedule.sunday[0].price : prices.weekend}</ItemPriceStyled>
            <ItemPriceTextStyled>
              <span>{currencyText}</span>
              <p>{durationText}</p>
            </ItemPriceTextStyled>
          </ItemBlockStyled>
          <ButtonStyled
              variant="outlined"
              title={bookText}
              onClick={() => scrollTo("#booking")}
          />
        </ItemStyled>

        <SmallWrapperStyled>
          <ItemStyled>
            <ItemTitleStyled noMargin>
              {useI18n("main.price.giftCards")}
            </ItemTitleStyled>
            <ButtonStyled
              variant="outlined"
              title={useI18n("main.price.moreDetails")}
              onClick={() => navigate(`/${city.slug}/gift-cards`)}
            />
          </ItemStyled>
          <ItemStyled>
            <ItemTitleStyled noMargin>
              {useI18n("main.price.birthdays")}
            </ItemTitleStyled>
            <ButtonStyled
              variant="outlined"
              title={useI18n("main.price.moreDetails")}
              onClick={() => navigate(`/${city.slug}/birthday`)}
            />
          </ItemStyled>
        </SmallWrapperStyled>
      </WrapperStyled>

      <HintTextStyled>
        {useI18n("main.price.hint1")}
        <br />
        {useI18n("main.price.hint2")}
      </HintTextStyled>
    </ContainerLayout>
  );
};
export default Price;

const TitleStyled = styled.p`
  font-size: 2.5rem;
  line-height: 1.28;
  text-align: center;
  @media ${(props) => props.theme.breakpoints.small} {
    font-size: 1.7rem;
  }
`;
const WrapperStyled = styled.div`
  display: grid;
  grid-template-columns: ${(props) =>
    props.isSamePrice ? "repeat(2, 1fr)" : "repeat(3, 1fr)"};

  margin-top: 5rem;
  gap: 20px;

  @media ${(props) => props.theme.breakpoints.tablet} {
    grid-template-columns: 1fr;

    margin-top: 3rem;
  }
`;
const ItemStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  position: relative;

  padding: 40px 25px;

  > * {
    z-index: 1;
  }

  &:before {
    content: "";

    width: 100%;
    height: 100%;

    position: absolute;
    top: 0;

    background-image: url(${(props) =>
      props.isHovered ? greenBrackets : greyBrackets});
    background-size: contain;
    background-repeat: no-repeat;
  }
  &:after {
    content: "";

    width: 100%;
    height: 100%;

    position: absolute;
    bottom: 0;

    background-image: url(${(props) =>
      props.isHovered ? greenBrackets : greyBrackets});
    background-size: contain;
    background-repeat: no-repeat;
    transform: rotate(180deg);
  }

 
  @media ${(props) => props.theme.breakpoints.medium} {
    padding: 30px 40px;
  }

  @media ${(props) => props.theme.breakpoints.mobile} {
    padding: 30px 50px;
  }
`;

const ItemTitleStyled = styled.div`
  margin-bottom: ${(props) => (props.noMargin ? null : "1rem")};
  min-height: 55px;

  font-size: 1.4rem;
  text-align: center;
  font-family: ${(props) => props.theme.fonts.futuraDemi};
  color: ${(props) => props.theme.colors.green};

  @media ${(props) => props.theme.breakpoints.small} {
    font-size: 1.2rem;
  }
`;
const ItemDescriptionStyled = styled.p`
  font-size: 1.1rem;
  line-height: 1.1;
  text-align: center;

  @media ${(props) => props.theme.breakpoints.small} {
    font-size: 0.9rem;
  }
`;

const ItemBlockStyled = styled.div`
  display: flex;
  align-items: center;

  margin: 20px 0;
`;
const ItemPriceStyled = styled.p`
  font-size: 2.8rem;
  font-family: ${(props) => props.theme.fonts.futuraDemi};
  line-height: 1.41;
`;
const ItemPriceTextStyled = styled.div`
  margin-left: 10px;
  span {
    font-size: 1.1rem;
    color: ${(props) => props.theme.colors.white};
  }
  p {
    font-size: 0.8rem;
    color: ${(props) => props.theme.colors.green};
  }
`;

const SmallWrapperStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  > div {
    flex: 1;
    padding: 20px;
    justify-content: center;
  }

  @media ${(props) => props.theme.breakpoints.tablet} {
    > div {
      padding: 30px 50px;
    }
  }
`;
const HintTextStyled = styled.p`
  max-width: 50%;
  margin: 80px auto 0;

  font-size: 0.8rem;
  color: ${(props) => props.theme.colors.grey};
  text-align: center;
  line-height: 1.37;

  @media ${(props) => props.theme.breakpoints.small} {
    color: ${(props) => props.theme.colors.white};
    max-width: 80%;
    margin: 50px auto 0;
  }

  @media ${(props) => props.theme.breakpoints.mobile} {
    max-width: 100%;
    margin: 50px auto 0;
  }
`;
const ButtonStyled = styled(Button)`
  min-width: 12rem;

  && {
    font-size: 1rem;
  }
  @media ${(props) => props.theme.breakpoints.mobile} {
    width: 100%;
  }
`;
