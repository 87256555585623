import React from "react"

import styled from "styled-components"

import ContainerLayout from "../../components/ui/containerLayout"

import { useI18n } from "../../../locale.js"

import rulesBg from "../../assets/images/bg/rulesBg.jpg"
import greenBrackets from "../../assets/images/elements/greenSmallBrackets.svg"
import fallingStar from "../../assets/images/elements/fallingStar.svg"

const Rules = () => {
  return (
    <WrapStyled>
      <ContainerLayout id="rules">
        <StarStyled src={fallingStar} alt="Falling star" />
        <StarTopStyled src={fallingStar} alt="Falling star" />

        <TitleStyled>
          <b>{useI18n("main.rules.title")}</b> {useI18n("main.rules.our")}
          <br /> {useI18n("main.rules.club")}
        </TitleStyled>

        <WrapperStyled>
          {/* <ItemStyled>
            <ItemTitleStyled>{useI18n("main.rules.password")}</ItemTitleStyled>
            <ItemDescriptionStyled>
              {useI18n("main.rules.passwordDetails")}
            </ItemDescriptionStyled>
          </ItemStyled> */}

          <ItemStyled>
            <ItemTitleStyled>{useI18n("main.rules.alcohol")}</ItemTitleStyled>
            <ItemDescriptionStyled>
              {useI18n("main.rules.warning")}
            </ItemDescriptionStyled>
          </ItemStyled>

          <ItemStyled>
            <ItemTitleStyled>
              {useI18n("main.rules.age")}
              <br /> {useI18n("main.rules.restriction")}
            </ItemTitleStyled>
            <ItemDescriptionStyled>
              {useI18n("main.rules.restrictionDetails")}
            </ItemDescriptionStyled>
          </ItemStyled>

          <ItemStyled>
            <ItemTitleStyled>{useI18n("main.rules.pregnant")}</ItemTitleStyled>
            <ItemDescriptionStyled>
              {useI18n("main.rules.pregnantDetails")}
            </ItemDescriptionStyled>
          </ItemStyled>

          <ItemStyled>
            <ItemTitleStyled>{useI18n("main.rules.visit")}</ItemTitleStyled>
            <ItemDescriptionStyled>
              {useI18n("main.rules.visitDetails")}
            </ItemDescriptionStyled>
          </ItemStyled>

          <ItemStyled>
            <ItemTitleStyled>
              {useI18n("main.rules.required")}
              <br /> {useI18n("main.rules.childAccompany")}
            </ItemTitleStyled>
            <ItemDescriptionStyled>
              {useI18n("main.rules.accompanyDetails")}
            </ItemDescriptionStyled>
          </ItemStyled>
        </WrapperStyled>

        <StarBottomStyled src={fallingStar} alt="Falling star" />
      </ContainerLayout>
    </WrapStyled>
  )
}
export default Rules

const WrapStyled = styled.div`
  padding-top: 6.5rem;

  position: relative;

  background-image: url(${rulesBg});
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: bottom;
  overflow: hidden;
  > * {
    z-index: 1;
  }

  &:before {
    content: "";

    height: 100%;
    width: 100%;

    position: absolute;
    top: 0;

    background: linear-gradient(
      to bottom,
      ${props => props.theme.colors.black},
      15%,
      rgba(3, 3, 3, 0.4)
    );
  }

  @media ${props => props.theme.breakpoints.small} {
    background-size: cover;
    background-position: center top;
  }
`

const TitleStyled = styled.p`
  margin-bottom: 2.2rem;

  font-size: 2.5rem;
  line-height: 1.14;
  text-align: center;
  br {
    display: none;
  }

  @media ${props => props.theme.breakpoints.small} {
    font-size: 1.7rem;
    br {
      display: block;
    }
  }
`
const WrapperStyled = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);

  margin-top: 5rem;
  @media ${props => props.theme.breakpoints.tablet} {
    grid-template-columns: 1fr 1fr;
  }

  @media ${props => props.theme.breakpoints.mobile} {
    grid-template-columns: 1fr;

    margin-top: 2rem;
  }
`
const ItemStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  margin: 20px 35px;

  @media ${props => props.theme.breakpoints.laptop} {
    margin: 20px 20px;
  }

  @media ${props => props.theme.breakpoints.mobile} {
    margin: 0 0 3rem;
  }
`

const ItemTitleStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  margin-bottom: 1.5rem;
  padding: 10px 30px;

  position: relative;

  font-size: 1.2rem;
  text-align: center;
  font-family: ${props => props.theme.fonts.futuraDemi};

  z-index: 1;
  height: 50px;

  br {
    display: none;
  }

  &:before {
    content: "";

    width: 15px;
    height: 100%;

    position: absolute;
    top: 0;
    left: 0;

    background-image: url(${greenBrackets});
    background-size: 15px 50px;
    background-repeat: no-repeat;
    background-position: bottom;
    transform: rotate(180deg);
  }

  &:after {
    content: "";

    height: 100%;
    width: 15px;

    position: absolute;
    top: 0;
    right: 0;

    background-image: url(${greenBrackets});
    background-size: 15px 50px;
    background-repeat: no-repeat;
    background-position: bottom;
  }

  @media ${props => props.theme.breakpoints.mobile} {
    width: unset;
    br {
      display: block;
    }
  }
`
const ItemDescriptionStyled = styled.p`
  font-size: 1.1rem;
  line-height: 1.41;
  text-align: center;

  @media ${props => props.theme.breakpoints.medium} {
    font-size: 1rem;
  }
`

const StarTopStyled = styled.img`
  width: 90px;
  height: 50px;
  position: absolute;

  right: 5%;
  top: -5.5rem;
`

const StarStyled = styled(StarTopStyled)`
  width: 150px;
  height: 90px;
  position: absolute;

  left: -5%;
  top: 0;
  right: unset;

  @media ${props => props.theme.breakpoints.mobile} {
    display: none;
  }
`

const StarBottomStyled = styled(StarTopStyled)`
  width: 110px;
  height: 70px;
  position: absolute;

  right: -15%;
  top: unset;
  bottom: 5%;

  @media ${props => props.theme.breakpoints.laptop} {
    display: none;
  }
`
